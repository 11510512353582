import { template as template_5aaede851a6c4f8582f1006194de53ff } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_5aaede851a6c4f8582f1006194de53ff(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
