import { template as template_7d108fde3723497d9ec539a65f9f4426 } from "@ember/template-compiler";
const FKText = template_7d108fde3723497d9ec539a65f9f4426(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
